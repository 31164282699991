import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '官网',
    // meta: {
    //   title: '梦支付官网'
    // },
    component: () => import('../views/front/index')
  },
  {
    path: '/about',
    name: '关于',
    component: () => import('../views/front/about.vue')
  },
  {
    path: '/service',
    name: '服务',
    component: () => import('../views/front/service.vue')
  },
  {
    path: '/contact',
    name: '联系',
    component: () => import('../views/front/contact.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/user/login/Login')
  },
  {
    path: '/Register',
    name: '注册',
    component: () => import('../views/user/register/Register')
  },
  {
    path: '/goPay',
    name: 'goPay',
    component: () => import('../views/pay/goPay')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/pay')
  },
  {
    path: '/payTrue',
    name: 'payTrue',
    component: () => import('../views/pay/payTrue')
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('../views/manage/Manage'),
    children:[
      {
        path: '/home',
        name: '个人中心',
        component: () => import('../views/index/Home')
      },
      {
        path: '/orderManage',
        name: '订单管理',
        component: () => import('../views/order/OrderManage')
      },
      {
        path: '/userInfo',
        name: '个人信息',
        component: () => import('../views/user/person/UserInfo')
      },
      {
        path: '/createManage',
        name: '开发设置',
        component: () => import('../views/API/CreateManage')
      },
      {
        path: '/apiDocument',
        name: 'API文档',
        component: () => import('../views/API/ApiDocument')
      },
      {
        path: '/userReal',
        name: '实名认证',
        component: () => import('../views/securityCenter/UserReal')
      },
      {
        path: '/accountOverview',
        name: '账户概况',
        component: () => import('../views/securityCenter/AccountOverview')
      },
      {
        path: '/balanceStatement',
        name: '余额明细',
        component: () => import('../views/securityCenter/BalanceStatement')
      },
      {
        path: '/accountToBeCredited',
        name: '收款账户',
        component: () => import('../views/securityCenter/AccountToBeCredited')
      },
      {
        path: '/resourceDownload',
        name: '资源下载',
        component: () => import('../views/resource/ResourceDownload')
      },
    ]
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有 hash 值，则滚动到对应的元素
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    // 如果没有 hash 值，则滚动到顶部
    else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '梦支付';
  next();
});


export default router
