import axios from 'axios'
import ElementUI from "element-ui";
import router from "@/router";


const request = axios.create({
    // baseURL: 'https://localhost:8081',
    // baseURL: 'https://172.17.0.3:8081', // Nginx监听的端口
    // baseURL: 'https://49.232.226.86:8081',
    baseURL: 'https://pay.dreamboy.top',
    // baseURL: 'https://490v412v46.zicp.fun',
    // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):null
    if (user){
        config.headers['PC-SaToken'] = user.tokenValue;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }

        if (typeof res === 'string') {
            try {
                res = JSON.parse(res);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }

        // 兼容服务端返回的字符串数据
        // if (typeof res === 'string') {
        //     // res = res ? JSON.parse(res) : res
        //     res = res ? JSON.parse(JSON.stringify(res)) : res
        // }

        console.log("response:",JSON.stringify(res))

        // 当权限验证不通过的时候给出提示
        if (res.code == "401"){
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
            router.push("/login");  // 使用 router.push
        }
        // if (res.code == "401"){
        //     ElementUI.Message({
        //         message:res.msg,
        //         type:'error'
        //     })
        //     this.$router.push("/login")
        // }

        if (res.code!== '200'){
            ElementUI.Message({
                message:res.msg,
                type:'error'
            })
        }

        return res;
    },
    error => {
        ElementUI.Message({
            message: "网络错误",
            type: 'error'
        });
        console.log(error);
        return Promise.reject(error);  // 取消注释以继续向上层传递错误
    }

    // error => {
    //     ElementUI.Message({
    //         message:"网络错误",
    //         type:'error'
    //     })
    //     console.log(error)
    //     // console.log('err' + error) // for debug
    //     // return Promise.reject(error)
    // }
)


export default request

