import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css'
import request from "@/utils/request";
import 'jquery';
import 'slick-carousel/slick/slick';  // 导入Slick Carousel
// 将 jQuery 绑定到全局对象上
window.jQuery = require('jquery');
window.$ = window.jQuery;
Vue.config.productionTip = false
Vue.use(ElementUI,{size:"mini"});
Vue.prototype.request=request


Vue.config.errorHandler = function (err, vm, info) {
  console.error('Error:', err.message); // 输出错误信息到控制台
  // 可以根据实际需求进行其他处理，比如发送错误日志到服务器等
};


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
